import axios from "axios";

const resetPassword=() => {

};

export function GetChild() {
  return axios.get("/w1/user/childs");
}


export default {
  resetPassword,
};
